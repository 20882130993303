import React, { createRef, useEffect, useState } from "react";
import {
  EmptyData,
  ReportContent,
  ReportControllers,
} from "ReportPortal/components";
import ReportExcel from "ReportPortal/components/molecules/ReportExcel";
import style from "./style.module.scss";
const complexColumn = [
  {
    className: "text-float-right",
    field: "noOfAssignments",
    format: "{0:n0}",
    showOnGrid: true,
    sortable: true,
    title: "Antal uppdrag",
  },
  {
    field: "invoicedAmount",
    format: "{0:C}",
    showOnGrid: true,
    sortable: true,
    title: "Fakturerat belopp (i kr)",
  },
];

const prepareColumns = (type, columnStruct, aggregatedFields) => {
  const selectedColumns = [...columnStruct].slice(0, 2);
  if (type === "distribute property group" && columnStruct.length > 1) {
    return [
      selectedColumns[0],
      {
        ...selectedColumns[1],
        value: "All",
        title: "All",
        subColumn: aggregatedFields?.map((x) => {
          return {
            ...x,
            field: `All-${x.field}`,
            title: `All-${x.field}`,
          };
        }) || [
          {
            ...selectedColumns[1].subColumn.slice(0, 1)[0],
            field: "All-noOfAssignments",
            title: "All-noOfAssignments",
          },
        ],
      },
    ];
  }
  return [...columnStruct].slice(0, 2);
};

const ReportCard = React.memo(
  ({
    table,
    isDataLoaded,
    totalFooterCell,
    excelFileName,
    handleExport,
    saveExcel,
    originalColumnStruct,
    data = [],
  }) => {
    if (isDataLoaded && data.length === 0 && !table.isHide) {
      return (
        <div className={style.emptyContainer}>
          <div className={style.header}>{table.title}</div>
          <div className={style.emptyData}>
            <EmptyData />
            <span>No records for selected date period</span>
            <ReportExcel
              handleExport={handleExport}
              data={data}
              columnStruct={originalColumnStruct}
              sheetName={table.excelSheetName}
            />
          </div>
        </div>
      );
    }
    if (table.isHide) {
      return (
        <ReportExcel
          handleExport={handleExport}
          data={data}
          columnStruct={originalColumnStruct}
          sheetName={table.excelSheetName}
        />
      );
    }

    const webColumnStruct = [...table.columnStruct].slice(
      table.startColumn || 0
    );

    const [columnStruct, setColumnStruct] = useState(
      prepareColumns(
        table.type,
        webColumnStruct,
        table.controllers?.aggregateCol
      )
    );
    console.log({ sliceLimit: table.sliceLimit });
    const SLICE_LIMIT = table.sliceLimit || 10;
    const [primaryColumn, setPrimaryColumn] = useState({
      ...webColumnStruct[1],
      value: "All",
      title: "All",
    });

    const [aggregateColumn, setAggregateColumn] = useState(
      table.controllers?.aggregateCol[0] || complexColumn[0]
    );

    const [activeView, setActiveView] = useState("chart");
    const _exporter = createRef();

    useEffect(() => {
      const defaultColumns = prepareColumns(
        table.type,
        webColumnStruct,
        table.controllers?.aggregateCol
      );
      setColumnStruct(defaultColumns);
      setPrimaryColumn(defaultColumns[1]);
    }, [table.columnStruct]);

    const handleAggregatedFieldSelect = (event) => {
      if (event) {
        const selectedAggregatedField = { ...event.value };
        // filter Primary Column based on selected aggregate field
        if (table.type === "distribute property group") {
          const processedPrimaryColumn = {
            ...primaryColumn,
            subColumn: primaryColumn.subColumn.filter((x) =>
              x.field.includes(selectedAggregatedField.field)
            ),
          };
          setColumnStruct([
            ...[...webColumnStruct].slice(0, 1),
            processedPrimaryColumn,
          ]);
        } else {
          setColumnStruct([
            ...[...webColumnStruct].slice(0, 1),
            selectedAggregatedField,
          ]);
        }
        setAggregateColumn(selectedAggregatedField);
      }
    };

    const handlePrimaryColumnDropDown = (event) => {
      if (event) {
        const selectedColumn = event.value;
        setPrimaryColumn({ ...selectedColumn });
        selectedColumn.subColumn = selectedColumn.subColumn.filter((x) =>
          x.field.includes(aggregateColumn.field)
        );
        setColumnStruct([...[...webColumnStruct].slice(0, 1), selectedColumn]);
      }
    };

    const sliceData = (data) => {
      const sortedData = data.sort(
        (a, b) => b[aggregateColumn.field] - a[aggregateColumn.field]
      );
      if (data.length <= SLICE_LIMIT) return sortedData;
      return addOtherDataAsListItem(
        sortedData,
        aggregateColumn.field,
        table.hideOtherRow
      );
    };

    const addOtherDataAsListItem = (data, key, hideOtherRow) => {
      if (!hideOtherRow) {
        return [...data].slice(0, SLICE_LIMIT);
      }
      const slicedData = [...data].slice(0, SLICE_LIMIT - 1);
      const totalOther = [...data]
        .slice(SLICE_LIMIT - 1)
        .reduce((prev, curr, index) => {
          return prev + curr[key];
        }, 0);
      const field = columnStruct[0].field;
      const otherItem = {};
      otherItem[field] = "Övrig";
      otherItem[key] = totalOther;
      slicedData.push(otherItem);
      return slicedData;
    };

    const sliceComplexData = (data) => {
      if (data.length === 0) return data;
      const key = `${primaryColumn.value}-${aggregateColumn.field}`;
      const sortedData = data.sort((a, b) => (b[key] || 0) - (a[key] || 0));
      if (data.length <= SLICE_LIMIT) {
        return sortedData;
      }
      return addOtherDataAsListItem(sortedData, key, table.hideOtherRow);
    };

    if (table.type === "distribute property group") {
      return (
        <div className={style.container}>
          <div className={style.header}>{table.title}</div>
          <ReportControllers
            primaryColumns={[
              { ...table.columnStruct[1], value: "All", title: "Alla" },
              ...[...table.columnStruct]
                .slice(1)
                .map((x) => ({ ...x, value: x.title })),
            ]}
            aggregatedFields={table.controllers?.aggregateCol || complexColumn}
            onAggregatedFieldSelect={handleAggregatedFieldSelect}
            onPrimaryColumnSelect={handlePrimaryColumnDropDown}
            onReportViewSet={setActiveView}
            onExcelDownload={saveExcel}
            activeView={activeView}
            type={table.type}
            description={table.description}
            controller={table.controllers}
            tableTotal={table.tableTotal}
            data={data}
          />
          <ReportContent
            isDataLoaded={!isDataLoaded}
            data={sliceComplexData(data)}
            columnStruct={columnStruct}
            primaryColumn={primaryColumn}
            aggregateColumn={aggregateColumn}
            activeView={activeView}
            table={table}
            totalFooterCell={totalFooterCell}
          />
          <ReportExcel
            handleExport={handleExport}
            data={data}
            columnStruct={originalColumnStruct}
            sheetName={table.excelSheetName}
          />
        </div>
      );
    }

    return (
      <div className={style.container}>
        <div className={style.header}>{table.title}</div>
        <ReportControllers
          aggregatedFields={
            table.controllers?.aggregateCol || [...table.columnStruct].slice(1)
          }
          onAggregatedFieldSelect={handleAggregatedFieldSelect}
          onReportViewSet={setActiveView}
          onExcelDownload={saveExcel}
          activeView={activeView}
          description={table.description}
          controller={table.controllers}
          tableTotal={table.tableTotal}
          data={data}
        />
        <ReportContent
          isDataLoaded={!isDataLoaded}
          data={sliceData(data)}
          activeView={activeView}
          columnStruct={columnStruct}
          primaryColumn={primaryColumn}
          aggregateColumn={aggregateColumn}
          table={table}
          excelFileName={excelFileName}
        />
        <ReportExcel
          handleExport={handleExport}
          data={data}
          columnStruct={originalColumnStruct}
          sheetName={table.excelSheetName}
        />
      </div>
    );
  },
  (pre, next) => {
    if (pre.isDataLoaded != next.isDataLoaded) return false;
    if (pre.table.columnStruct != next.table.columnStruct) return false;
    if (!pre.data && next.data) return false;
    return !(pre.data?.length !== next.data?.length);
  }
);

export default ReportCard;
