/* eslint-disable react/button-has-type */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./style.scss";

const TabButton = ({ label, disabled, disabledClassName, ...props }) => {
  const btnClass = classNames({
    "tv-tab_button": true,
    [`${disabledClassName}`]: disabled,
  });
  return (
    <button
      type="button"
      {...props}
      className={`${btnClass} ${props.className}`}
    >
      {label}
    </button>
  );
};

TabButton.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  disabledClassName: PropTypes.string,
  label: PropTypes.string,
};

TabButton.defaultProps = {
  className: "",
  disabledClassName: "tv-tab_button--disabled",
  disabled: false,
  label: "Button",
};

export default TabButton;
