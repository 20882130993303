module.exports = {
  faqData: {
    rowOne: [
      {
        id: 'rowOne-columnOne',
        question: 'Hur lägger jag mig tillgänglig?',
        answer: `Transvoice tillsätter uppdrag efter högsta tillgängliga kompetens. 
          För att få förfrågningar så måste du lägga dig tillgänglig. Det är mycket viktigt att den tillgänglighet du anger är aktuell. Tillgänglighet kan du lämna på två olika sätt: || 
          1. Schemalagd tillgänglighet hittar du under Min profil och passar dig som jobbar i större utsträckning för Transvoice. Du lägger in den tid du önskar jobba en eller flera dagar i veckan, alltså den tid som du är tillgänglig för uppdrag. 
          Exempelvis mån-fre 07:00-18:00. Den tid du lägger in här gäller sedan tillsvidare som din Schemalagda tillgänglighet. Det är mycket viktigt att den tillgänglighet du anger är aktuell. Kortare pass där du är Upptagen hanterar du sedan i Tillgänglig/Upptagen. ||
          2. Enkel Tillgänglighet, som du hittar under Tillgänglig/Upptagen på startsidan, passar istället dig som önskar lägga dig tillgänglig korta eller långa pass dag för dag, lite här och var.
          `,
      },
      {
        id: 'rowOne-columnTwo',
        question: 'Hur fungerar uppdragsväljaren?',
        answer: ` Under Min profil hittar du Uppdragsväljaren. Här kan du välja vilken typ av uppdrag du vill få förfrågningar på. Ju högre kompetens du har desto fler val kan du göra. Valen du kan göra är efter Typ(telefon, kontakt etc), 
        Område(migration, sjukvård etc) och Avtal(Region Stockholm, Arbetsförmedlingen etc). Tänk på att det är dina val här som helt styr vilka uppdrag du kommer att få förfrågningar på.
        `,
      },
    ],
    rowTwo: [
      {
        id: 'rowTwo-columnOne',
        question: 'Hur rapporterar jag ett uppdrag?',
        answer: `När starttiden på ett uppdrag har passerats med 5 minuter så får det status Utfört och kan då rapporteras. Ha som regel att alltid rapportera ett uppdrag direkt efter att det är utfört. Var mycket noga med att rapportera den faktiska tolkningstiden. Vid distanstolkning skall alltid sluttiden stämmas av med kund. Vid kontakttolkning skall kund alltid lämna en signatur och detta kan endast göras via appen. Var noga med att ange korrekt resa vid de uppdrag som tillåter detta. Vilka uppdrag/avtal som godkänner reseersättning och/eller utlägg framgår i aktuell Arvodeslista. 
        Uppdrag som ger reseersättning måste rapporteras samtidigt som övriga uppdrag samma dag, för att reseersättning skall utgå. Uppdrag som inte rapporteras inom 60 dagar förfaller.

        ||När uppdraget är rapporterat skickas en kopia till kundens egen portal. Lämna gärna ett betyg på uppdraget tillsammans med en kommentar. Positivt som negativt. Kommentaren och betyget ser bara Transvoice. 
        Här kan vi få in och samla viktig information, till exempel om det har varit dålig ljudkvalitet under uppdraget.`,
      },
      {
        id: 'rowTwo-columnTwo',
        question: 'Vad får jag för arvode för uppdrag?',
        answer: `Vi har många olika avtal där det råder olika arvode. För att se vilka arvoden som gäller för respektive uppdrag/avtal; gå in i Arvodeslistor under Min profil. Du kan även se ett preliminärt arvode på din förfrågan. 
        Detta är endast ett preliminärt arvode baserat på den bokade tiden. Det faktiska arvodet kan ändras beroende på till exempel den rapporterade tiden för det utförda uppdraget.`,
      },
    ],
    rowThree: [
      {
        id: 'rowThree-columnOne',
        question: 'När ska jag rapportera mina uppdrag för att få betalt?',
        answer: `Uppdrag som rapporteras innan den sista vardagen varje månad betalas ut den 25:e månaden efter. Ha som vana att rapportera uppdrag direkt efter att det är utfört eller samma dag. 
        Uppdrag som ger reseersättning måste rapporteras samtidigt som övriga uppdrag samma dag, för att reseersättning skall utgå. Uppdrag som inte rapporteras inom 60 dagar förfaller.`,
      },
      {
        id: 'rowThree-columnTwo',
        question: 'Vad händer om kunden avbokar uppdraget jag åtagit mig?',
        answer:
          'Inom de flesta avtal så gäller att; vid kontakttolkningsuppdrag så får du betalt om uppdraget avbokas inom 18 timmar till uppdragsstart och vid telefontolkning är det 2 timmar som gäller. Du kan se på avbokningen om du får betalt eller ej.',
      },
    ],
    rowFour: [
      {
        id: 'rowFour-columnOne',
        question: 'Kan jag själv påverka min uppdragsvolym?',
        answer: `Uppdragsvolym skiljer sig mycket beroende på språk. Det handlar om hur efterfrågan ser ut på just det språket och hur många tolkar det finns med hög formell kompetens. Inom vissa språk finns det generellt sett väldigt gott om hög formell kompetens. Inom andra språk är det en stor brist. 
          Vid planering utgår vi i första hand från högsta formella kompetens i kombination med anmäld tillgänglighet. Den absolut största påverkan du kan göra för din uppdragsvolym är att kompetensutveckla dig. Lägg dig tillgänglig löpande och gärna med så lång framförhållning som möjligt. Det är också bra om du ser till att inte begränsa dig för några uppdrag i Uppdragväljaren.
          `,
      },
      {
        id: 'rowFour-columnTwo',
        question: 'Hur ser jag min lön?',
        answer: 'Den ser du under Ersättning under Min profil',
      },
    ],
    rowFive: [
      {
        id: 'rowFive-columnOne',
        question: 'När betalas lön ut?',
        answer:
          'Lön betalas ut en månad i efterskott. Exempel: Lön för de uppdrag som du rapporterar innan den sista vardagen varje månad betalas ut den 25:e månaden efter.',
      },
      {
        id: 'rowFive-columnTwo',
        question: 'När betalas lön ut?',
        answer:
          'Lön betalas ut en månad i efterskott. Exempel: Lön för de uppdrag som du rapporterar innan den sista vardagen varje månad betalas ut den 25:e månaden efter.',
      },
    ],
    rowSix: [
      {
        id: 'rowSix-columnOne',
        question: 'Vem kontaktar jag gällande lönefrågor?',
        answer:
          'Du når vår ekonomiavdelning på telefon nr 08-120 800 75 mellan 08.00-12.00 eller på mejladress <a href="mailto:administration@transvoice.se">administration@transvoice.se</a>',
      },
      {
        id: 'rowSix-columnTwo',
        question:
          'Jag är intresserad av att fortbilda mig inom yrket, var hittar jag information om det?',
        answer:
          'Det finns flera vägar att gå för att fortbilda sig som tolk. På <a href="https://www.blitolk.nu/" target="_blank">www.blitolk.nu</a> kan du läsa mer om grundutbildningar som finns, om validering av tidigare utbildningar och om fristående fördjupningskurser inom olika ämnen.',
      },
    ],
    rowSeven: [
      {
        id: 'rowSeven-columnOne',
        question: 'Jag behöver en ny tolklegitimation, hur går jag tillväga?',
        answer:
          'Mejla foto och tolknummer till <a href="mailto:blitolk@transvoice.se">blitolk@transvoice.se</a> så kommer en tolklegitimation hem till dig på posten. Tänk på att fotot ska vara professionellt och taget rakt framifrån med ljus bakgrund.',
      },
      {
        id: 'rowSeven-columnTwo',
        question: 'Vilka formella kompetensnivåer finns det?',
        answer: `Vi kategoriserar tolkar i fem olika formella kompetensnivåer: || <h6>Auktoriserad tolk med rätts- eller sjukvårdskompetens (RT/ST)</h6> 
        || Auktoriserad tolk som genom ytterligare ett kunskapsprov hos Kammarkollegiet skaffat sig specialkompetens inom juridik eller sjukvård. 
        || <h6>Auktoriserad tolk (AT)</h6> || Av Kammarkollegiet godkänd i ett mycket krävande kunskapsprov som både är muntligt och skriftligt. 
        || <h6>Utbildad tolk (UT)</h6> || Har ett utbildningsbevis från en sammanhållen grundutbildning som står under tillsyn av MYh (Myndigheten för yrkeshögskolan) eller TÖI (Tolk- och översättarinstitutet) efter år 2006 eller som genom en validering av sina kunskaper och tidigare utbildning erhållit kompetensen av MYh. 
        || <h6>Grundutbildad tolk AMU (GT)</h6> || Har ett utbildningsbevis från en genomförd arbetsmarknadsutbildning (AMU) för tolkar.
        || <h6>Registrerad tolk (Reg.)</h6> || Har genomgått minst en introduktionsutbildning eller grundkurs och är sedan testad och godkänd av Transvoice.`,
      },
    ],

    rowEight: [
      {
        id: 'rowEight-columnOne',
        question: 'Vilken är Transvoice tillsättningspolicy?',
        answer: `Transvoice har en tydlig policy att alltid tillsätta uppdraget med den högsta tillgängliga kompetensen för sammanhanget i kombination med anmäld tillgänglighet.
          ||    
          1. Kompetens(Högsta tillgängliga kompetens först) ||
          2. Kvalitet på utförande (Utförda uppdrag utan anmärkningar) ||
          3. Acceptansgrad (Hur många förfrågningar man accepterat när man anmält tillgänglighet) || 
          En grundförutsättning för att få uppdrag från Transvoice är att man anmält tillgänglighet via appen eller tolkportalen.
          `,
      },
      {
        id: 'rowEight-columnTwo',
        question: 'Varför försvann min förfrågan innan jag hade svarat?',
        answer: `På varje förfrågan så kan du se svarstiden som gäller för att acceptera eller avböja uppdraget. Det är viktigt att du accepterar eller avböjer så fort du kan. 
        Om du inte har svarat när tiden går ut så försvinner uppdraget helt från dig och går vidare till nästa tolk. Svarstiden för uppdraget styrs av avtalet och typen. 
        Svarstiden för de flesta uppdrag är 2-5 timmar. Om du avböjer förfrågan så försvinner uppdraget helt från dig. Likaså om kund skulle avboka uppdraget, under tiden som den ligger som förfrågan, så försvinner uppdraget från dig.`,
      },
    ],
    rowNine: [
      {
        id: 'rowNine-columnOne',
        question: 'Jag ligger tillgänglig, varför får jag inga uppdrag på förfrågan?',
        answer: `Börja med att se så att du inte har begränsat dig för några uppdrag i Uppdragsväljaren.
        Uppdragsvolym skiljer sig mycket beroende på språk. Det handlar om hur efterfrågan ser ut på just det språket och hur många tolkar det finns, och hur många tolkar det finns med hög formell kompetens. 
        Inom vissa språk finns det generellt sett väldigt gott om hög formell kompetens. Inom andra språk är det en stor brist. || Vid planering utgår vi i första hand från högsta formella kompetens i kombination med anmäld tillgänglighet. 
        Har du hög kompetens inom ett språk, där efterfrågan är stor i kombination med att hög kompetens är en brist, så ska du få mycket förfrågningar under den tiden du lagt dig tillgänglig. Efter kompetens och tillgänglighet så planerar vi även efter ytterligare två parametrar. Dessa är Kvalitet på utförande (Utförda uppdrag utan anmärkningar) och Acceptansgrad (Hur många förfrågningar man accepterat när man anmält tillgänglighet). Dessa parametrars påverkan är normalt sätt minimal. Du kan alltid kontakta oss om du har ytterligare frågor eller funderingar.`,
      },
     
    ],
  },
};
