import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Report from "./Report";
import { NotFound } from "../../../Share/components";

const ReportContainer = ({ reports, reportID, dateRange }) => {
  const report = reports[reportID];
  return report ? (
    <Report
      reportDetail={report}
      reportId={reportID}
      dateRange={dateRange}
      key={reportID}
    />
  ) : (
    <NotFound />
  );
};

ReportContainer.propTypes = {
  reports: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dateRange: PropTypes.shape({}).isRequired,
};

ReportContainer.defaultProps = {};

const mapStateToProps = (state, props) => {
  const { reports } = state.reportDetails;
  return {
    reports,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ReportContainer);
