import { combineReducers } from 'redux';

import app from './app';
import authentication from './authentication';
import reportDetails from './reportDetails';
import dataSources from './dataSources';
import reportDataSources from './reportDataSource';
import dashboard from './dashboard';
import user from './user';


export default combineReducers({
  app,
  user,
  reportDataSources,
  authentication,
  reportDetails,
  dataSources,
  dashboard,
});
