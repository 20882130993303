/* eslint-disable react/require-default-props */
/* eslint-disable no-undef */
import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";

import "./style.scss";
import {
  numberFormat,
  processGroupByData,
  summationBasedOnKey,
} from "../../../../Share/utils/reportUtil";
import { Heading } from "../..";
import { isEqual } from "lodash";
import classNames from "classnames";
import { ThreeDotLoader } from "Share/components";

class WidgetPieCard extends Component {
  shouldComponentUpdate(nextProps) {
    const { data } = this.props;
    if (!isEqual(data, nextProps.data)) {
      return true;
    }

    return false;
  }
  calculateSeriesColors = (data) => {
    const initialValue = 40;
    return data.map((x, index) => {
      const saturation = initialValue + index * 10;
      return `hsl(260 62% ${saturation}%)`;
    });
  };
  bindColorData = (data, colorMap = {}, categoryField) => {
    const initialValue = 230;

    return data.map((x, index) => {
      const saturation = initialValue + index * 170;
      const mapColor =
        colorMap[x[categoryField]] || `hsl(${saturation} 90% 40%)`;
      return { ...x, color: mapColor };
    });
  };
  calculateTotal = () => {
    const { field, data } = this.props;

    const sum = summationBasedOnKey([...data], field);
    return sum;
  };
  calculateToolTipValue = (value) => {};
  renderTooltip = (context) => {
    const { category, percentage } = context.point || context;
    return (
      <div>
        {category} :{" "}
        {percentage.toLocaleString("se-SV", {
          style: "percent",
          minimumFractionDigits: 0,
        })}
      </div>
    );
  };
  render() {
    const {
      title,
      description,
      field = "invoicedAmount",
      categoryField = "monthYear",
      colorField = "color",
      orientation = "",
      appendText = "",
      gridArea = "",
      isLoading = false,
      data,
    } = this.props;
    const colorMap = {
      Öppen: "#ff9b4e",
      Bokad: "#6478e6",
      Utförd: "#18ad79",
      Avbokad: "#74737b",
      Avböjd: "#e94242",
    };
    const total = this.calculateTotal();
    const seriesColors = this.calculateSeriesColors(data);
    const widgetClass = classNames({
      "kpi-widget-pie-card__container  align-items-center": true,
      "kpi-widget-pie-card__container--vertical": orientation === "vertical",
    });
    const sortData = (first, second) => {
      return second[field] - first[field];
    };
    const coloredDataSet = this.bindColorData(
      data,
      colorMap,
      categoryField
    ).sort(sortData);
    return (
      <div className={widgetClass} style={{ gridArea }}>
        <div className="g-col-5 kpi-widget-pie-card__content ">
          <Heading variant="primary" text={title} />
          <Heading variant="secondary">{description}</Heading>
          <Chart
            renderAs="canvas"
            style={{ width: 250, height: 250 }}
            seriesColors={seriesColors}
          >
            <ChartLegend visible={false} />
            <ChartTooltip render={this.renderTooltip} />

            <ChartSeries>
              {data?.length != 0 ? (
                //   <ChartTooltip format="{0}" />

                <ChartSeriesItem
                  type="pie"
                  overlay={{
                    gradient: "glass",
                  }}
                  tooltip={{ visible: true, format: "{0:N0} uppdrag" }}
                  data={coloredDataSet}
                  colorField={colorField}
                  categoryField={categoryField}
                  field={field}
                />
              ) : (
                <ChartSeriesItem
                  type="pie"
                  overlay={{
                    gradient: "glass",
                  }}
                  data={{ field: 100, catagory: "catagory" }}
                  field="field"
                />
              )}
            </ChartSeries>
          </Chart>
        </div>
        <div className="g-col-7 kpi-widget-pie-card__legend-area ">
          {data?.length != 0 &&
            coloredDataSet.map((x, index) => (
              <div className="grid  align-items-center">
                <div
                  className="g-col-1 kpi-widget-pie-card__legends"
                  style={{ background: x[colorField] }}
                />
                <Heading variant="secondary" className="g-col-9 ">
                  {x[categoryField]}
                </Heading>{" "}
                <Heading variant="text" className="g-col-1 ">
                  {`${Number(x[field] / total).toLocaleString("se-SV", {
                    style: "percent",
                    minimumFractionDigits: 0,
                  })}`}
                </Heading>
              </div>
            ))}
        </div>
        {isLoading && (
          <div className="kpi-widget-pie-card__loader-overlay">
            <ThreeDotLoader />
          </div>
        )}
      </div>
    );
  }
}

WidgetPieCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  field: PropTypes.string,
  orientation: PropTypes.string,
  colorField: PropTypes.string,
  appendText: PropTypes.string,
  categoryField: PropTypes.string,
  style: PropTypes.shape({}),
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

export default React.memo(WidgetPieCard);

const test = [
  {
    interpretationType: "......",
    invoicedAmount: 0,
  },
  { interpretationType: "......", invoicedAmount: 0 },
  { interpretationType: "......", invoicedAmount: 0 },
  { interpretationType: "...... ", invoicedAmount: 0 },
];
