/* eslint-disable max-len */
import axios from "axios";
import { LOCAL_STORE_APIKEY } from "../../Share/constants";
import { errorInterceptor, responseInterceptor } from "./apiInterceptor";

const apiVersion = process.env.REACT_APP_REPORT_API_VERSION;

const reportAxios = axios.create({
  baseURL: process.env.REACT_APP_REPORT_API,
});
// api interceptors
reportAxios.interceptors.response.use(responseInterceptor, errorInterceptor);

// eslint-disable-next-line import/prefer-default-export
export function GetDataFromScource(
  urlPart,
  from,
  to,
  agreements,
  apiKey,
  groupColumns = ""
) {
  return reportAxios.get(
    `/api/${apiVersion}/reportData/${urlPart}?from=${from}&to=${to}&agreements=${agreements}&groupColumns=${
      groupColumns || ""
    }`,
    {
      headers: {
        customerNumber: 0,
        apiKey: apiKey,
      },
    }
  );
}
