import React from "react";
import { connect } from "react-redux";
import { NavigationLink, Translate } from "../..";
import "./style.scss";

function HomeSideNav({ reports }) {
  const reportLinks = [...Object.values(reports)];
  return (
    <div className="kpi-home-sidenav__container navigation tv-navigationlink">
      <NavigationLink exact to="/" className="nav-item" text="Dashboard" />
      {reportLinks
        .filter((y) => !y.isHide)
        .map((report) => (
          <NavigationLink
            key={report.id}
            to={`/report/${report.id}`}
            className="nav-item"
            text={Translate({ content: report.title })}
          />
        ))}
    </div>
  );
}

const mapStateToProps = (state) => ({
  reports: state.reportDetails.reports || [],
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HomeSideNav);
