/* eslint-disable react/require-default-props */
/* eslint-disable no-undef */
import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartCategoryAxisTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";

import "./style.scss";
import {
  numberFormat,
  processGroupByData,
  kFormatter,
  summationBasedOnKey,
} from "../../../../Share/utils/reportUtil";
import { Heading } from "../..";
import { isEqual, memoize } from "lodash";
import { convertStringToDate } from "Share/utils/dateUtil";
import { months } from "moment";
import { ThreeDotLoader } from "Share/components";

class WidgetBasicCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartProps: this.selectChartProps(props.chartType),
    };
  }
  shouldComponentUpdate(nextProps) {
    const { data } = this.props;

    if (!isEqual(data, nextProps.data)) {
      return true;
    }

    return false;
  }
  calculateTotal = () => {
    const { data, field, appendText } = this.props;
    let sum = numberFormat(summationBasedOnKey([...data], field), 0);
    if (appendText === "m") {
      sum = numberFormat(summationBasedOnKey([...data], field), 0);
    }
    if (appendText === "h") {
      sum = numberFormat(summationBasedOnKey([...data], field) / 60, 0);
    }
    return sum;
  };
  calculateToolTipValue = (value) => {
    const { appendText } = this.props;
    let sum = numberFormat(value, 0);
    if (appendText === "m") {
      sum = numberFormat(value, 0);
    }
    if (appendText === "h") {
      sum = numberFormat(value / 60, 0);
    }
    return `${sum} ${appendText}`;
  };

  renderTooltip = (context) => {
    const { category, value } = context.point || context;
    return (
      <div>
        {category} : {this.calculateToolTipValue(value)}
      </div>
    );
  };
  selectChartProps = (chartType = 0) => {
    switch (chartType) {
      case 0:
        return {
          type: "area",
          color: "#EEF6FE",
          labels: { visible: false },
          majorGridLines: { visible: false },
        };
      case 1:
        return {
          type: "area",
          color: "#EEF6FE",
          labels: { visible: false },
          majorGridLines: { visible: false },
          markers: {
            type: "circle",
            visible: true,
            border: { color: "#258CF4", width: 1, dashType: "solid" },
          },
        };
      case 2:
        return {
          type: "area",
          color: "#EEF6FE",
          labels: { visible: true },
          majorGridLines: { visible: true },
          markers: {
            type: "circle",
            visible: true,
            border: { color: "#258CF4", width: 1, dashType: "solid" },
          },
        };
      default:
        return {
          type: "area",
          color: "#EEF6FE",
          labels: { visible: false },
          majorGridLines: { visible: false },
        };
    }
  };

  render() {
    const {
      title,
      description,
      field = "invoicedAmount",
      category = "monthYear",
      orientation = "",
      appendText = "",
      chartType = 0,
      data,
      isLoading,
    } = this.props;
    const total = this.calculateTotal();
    const { chartProps } = this.state;
    return (
      <div className="kpi-widget-basic-card__container">
        <Heading variant="primary" text={title} />
        <Heading variant="big-text">{`${total} ${appendText}`}</Heading>
        <Chart
          axisDefaults={{
            labels: chartProps.labels,
            line: { visible: false },
            majorGridLines: chartProps.majorGridLines,
          }}
          key={title}
          style={{ height: 120 }}
          renderAs="canvas"
        >
          <ChartTooltip render={this.renderTooltip} />
          <ChartSeries>
            {data.length > 1 && (
              <ChartSeriesItem
                type={chartProps.type}
                data={data}
                field={field}
                categoryField={category}
                line={{ color: "#258CF4", width: 2, style: "smooth" }}
                labels={chartProps.seriesLabels}
                markers={chartProps.markers}
                color={chartProps.color}
              />
            )}
          </ChartSeries>
          {chartType === 2 && (
            <>
              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  labels={{
                    step: data.length > 4 ? 1 : data.length / 4,
                    content: (e) => {
                      return e.text.slice(0, 3);
                    },
                  }}
                />
              </ChartCategoryAxis>
              <ChartValueAxis>
                <ChartValueAxisItem
                  labels={{
                    step: 3,
                    content: (e) => {
                      return kFormatter(e.text || 1) || 0;
                    },
                  }}
                />
              </ChartValueAxis>
            </>
          )}
        </Chart>
        {data.length === 1 && (
          <div className="kpi-widget-basic-card__data-overlay">
            <span>Not enough time range to plot a graph</span>
          </div>
        )}
        {isLoading && (
          <div className="kpi-widget-basic-card__loader-overlay">
            <ThreeDotLoader />
          </div>
        )}
      </div>
    );
  }
}

WidgetBasicCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  field: PropTypes.string,
  orientation: PropTypes.string,
  appendText: PropTypes.string,
  category: PropTypes.string,
  style: PropTypes.shape({}),
  data: PropTypes.arrayOf(PropTypes.shape({})),
};
export default React.memo(WidgetBasicCard);
