import React, { useRef } from "react";
import PropTypes from "prop-types";
import {
  LogoLink,
  NavigationLink,
  ProfileLink,
  Translate,
  SettingMenuDropdown,
} from "../../../components";
import "./style.scss";

const TopNavBar = (props) => {
  const { customerName } = props;
  const dropMenuEL = useRef(null);
  return (
    <div className="tv-topnavbar__container d-sm-none d-md-block d-none d-sm-block">
      <div className="d-flex tv-topnavbar__flex">
        <div className="me-auto me-5">
          <LogoLink />
        </div>
        <div>
          <div className="navigation tv-navigationlink">
            <div className="navbar-expand">
              <div className="navbar-nav justify-content-end">
                <NavigationLink
                  exact
                  to="/"
                  className="nav-item"
                  text={Translate({ content: "sideBar.dashboard" })}
                />
                <NavigationLink
                  to="/contact-transvoice"
                  className="nav-item"
                  text={Translate({ content: "sideBar.contactTransvoice" })}
                />
              </div>
            </div>
          </div>
        </div>
        <div>{/* <IconLink className="tv-topnavbar__noti-icon" /> */}</div>
        <div>
          <SettingMenuDropdown button={<ProfileLink />} ref={dropMenuEL}>
            <div className="tv-avatar-list__menu">
              <div
                className="tv-avatar-list__menu-item tv-avatar-list__menu-item--customer-name"
                tabIndex="-1"
              >
                {`Hej ${customerName || "Användare"}`}
              </div>
              <NavigationLink
                to="/login"
                className="tv-avatar-list__menu-item tv-avatar-list__menu-item--logout"
                text="Logga ut"
                onClick={() => {
                  localStorage.clear();
                }}
              />
            </div>
          </SettingMenuDropdown>
        </div>
      </div>
    </div>
  );
};

TopNavBar.propTypes = {
  customerName: PropTypes.string,
};

TopNavBar.defaultProps = {
  customerName: "",
};

export default TopNavBar;
