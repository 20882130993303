import React from "react";

function EmptyData() {
  return (
    <>
      <svg
        width="250"
        height="255"
        viewBox="0 0 81 88"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.852577 32.0336C0.329075 32.6757 0.418412 33.6287 1.05212 34.1623C1.68582 34.6959 2.62392 34.608 3.14742 33.9659L0.852577 32.0336ZM3.14742 33.9659L17.6023 16.2375L15.3074 14.3052L0.852577 32.0336L3.14742 33.9659Z"
          fill="#C4C4C4"
        />
        <path
          d="M26.7716 24.436C26.2481 25.078 26.3374 26.0311 26.9712 26.5646C27.6049 27.0982 28.543 27.0103 29.0665 26.3682L26.7716 24.436ZM48.4816 1.86109C48.4037 1.02979 47.6772 0.418068 46.8589 0.494771L33.5244 1.74472C32.7062 1.82142 32.106 2.5575 32.1839 3.38881C32.2619 4.22011 32.9884 4.83183 33.8066 4.75513L45.6595 3.64407L46.7882 15.6857C46.8662 16.517 47.5927 17.1288 48.4109 17.052C49.2292 16.9753 49.8294 16.2393 49.7514 15.408L48.4816 1.86109ZM29.0665 26.3682L48.1474 2.96611L45.8526 1.03384L26.7716 24.436L29.0665 26.3682Z"
          fill="#C4C4C4"
        />
        <path
          d="M17.4226 14.1255C16.7983 13.581 15.8588 13.6527 15.3243 14.2856C14.7898 14.9185 14.8627 15.873 15.487 16.4175L17.4226 14.1255ZM15.487 16.4175L26.8347 26.3136L28.7703 24.0215L17.4226 14.1255L15.487 16.4175Z"
          fill="#C4C4C4"
        />
        <rect x="6" y="43" width="8" height="29" fill="#C4C4C4" />
        <rect x="21" y="37" width="8" height="35" fill="#C4C4C4" />
        <rect x="36" y="29" width="8" height="43" fill="#C4C4C4" />
        <circle cx="56" cy="63" r="25" fill="white" />
        <circle cx="56" cy="63" r="20" fill="#EBEBEB" />
        <path
          d="M59.3333 55.7021C56 69 59.3333 55.7021 56 69C52.6667 55.7021 56 69.7871 52.6667 55.7021C52.6667 52.0006 54.159 49 56 49C57.8409 49 59.3333 52.0006 59.3333 55.7021Z"
          fill="#FA0A5F"
        />
        <path
          d="M57.5151 72.9394C57.5151 73.7762 56.8368 74.4545 56 74.4545C55.1632 74.4545 54.4848 73.7762 54.4848 72.9394C54.4848 72.1026 55.1632 71.4242 56 71.4242C56.8368 71.4242 57.5151 72.1026 57.5151 72.9394Z"
          fill="#FA0A5F"
        />
      </svg>
    </>
  );
}

export default EmptyData;
