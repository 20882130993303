import React from "react";
import { useSelector } from "react-redux";
import {
  DashboardWidgetContainer,
  InvoicedAssignmentWidget,
} from "ReportPortal/components";
import "./style.scss";

function Dashboard() {
  const widgets = useSelector((state) => state.dashboard.widgets);
  return (
    <div className="  tv-dashboard-grid-template">
      <InvoicedAssignmentWidget key="1_InvoicedAssignmentWidget" />
      <DashboardWidgetContainer key={`${widgets[0].title}`} {...widgets[0]} />
      <DashboardWidgetContainer
        key={`${widgets[3].title}`}
        {...widgets[3]}
        orientation="vertical"
      />
      <DashboardWidgetContainer key={`${widgets[1].title}`} {...widgets[1]} />
      <DashboardWidgetContainer key={`${widgets[2].title}`} {...widgets[2]} />
    </div>
  );
}

export default Dashboard;
