/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import './style.scss';

function Heading(props) {
  const {
    variant = "black", text, children, className = '',
  } = props;
  const headingClass = classNames({
    'kpi-heading': true,
    'kpi-heading--primary': variant === 'primary',
    'kpi-heading--secondary': variant === 'secondary',
    'kpi-heading--big-text': variant === 'big-text',
    'kpi-heading--text': variant === 'text',
  });
  return (
    <span className={`${headingClass} ${className}`}>
      {!children ? text : children}
    </span>
  );
}


export default Heading;
