/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./style.scss";
import { Heading } from "ReportPortal/components";
import { summationBasedOnKey } from "Share/utils/reportUtil";
import { ThreeDotLoader } from "Share/components";

function WidgetBasicTable(props) {
  const {
    title = "Fördelning per språk",
    description = "",
    data = [],
    gridArea = "",
    tableMainField = "Spark",
    field = "noOfAssignments",
    categoryField = "skill",
    isLoading = false,
  } = props;

  const [processData, setProcessData] = useState([]);
  const calculateTotal = () => {
    const sum = summationBasedOnKey([...data], field);
    return sum;
  };

  const selectTopEight = (data = []) => {
    if (data.length < 8) return data.sort((a, b) => b[field] - a[field]);
    const sortedData = data.sort((a, b) => b[field] - a[field]);
    const firstEight = sortedData.slice(0, 7);
    const others = {
      [categoryField]: "Övrig",
      [field]: sortedData
        .slice(7)
        .reduce(
          (acc, current) => acc + (current[field] ? current[field] : 0),
          0
        ),
    };
    return [...firstEight, others];
  };

  const [total, setTotal] = useState(1);

  useEffect(() => {
    setTotal(calculateTotal());
    setProcessData(selectTopEight(data));
  }, [data]);

  return (
    <div className="kpi-widget-basic-table__container" style={{ gridArea }}>
      <div className="kpi-widget-basic-table__header">
        <Heading variant="primary" text={title} />
        <Heading variant="secondary">{description}</Heading>
      </div>
      <div className="kpi-widget-basic-table__content">
        <div className="kpi-widget-basic-table__content_header">
          <div>{tableMainField}</div>
          <div>Antal uppdrag</div>
          <div>Procentsats</div>
        </div>
        {processData.map((item) => {
          const percent = Number(item[field] / total);
          return (
            <div className="kpi-widget-basic-table__content_row">
              <div>{item[categoryField]}</div>
              <div>{(item[field] || 0).toLocaleString("sv")}</div>
              <div>
                <span>
                  {percent.toLocaleString("sv", {
                    style: "percent",
                  })}
                </span>
              </div>
              <div className="progress">
                <div
                  className="progress-bar h-100"
                  role="progressbar"
                  style={{ width: `${percent * 100}%` }}
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
            </div>
          );
        })}
      </div>
      {isLoading && (
        <div className="kpi-widget-basic-table__loader-overlay">
          <ThreeDotLoader />
        </div>
      )}
    </div>
  );
}

WidgetBasicTable.prototype = {
  title: PropTypes.string,
  tableMainField: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

export default WidgetBasicTable;
