import React, { Component } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { ChangeReportDatePeriod } from "../../actions";

import { Translate } from "../../../Share/components";
import { isEqual } from "lodash";

const propTypes = {
  reportId: PropTypes.number.isRequired,
  children: PropTypes.node,
  style: PropTypes.shape({}),
  saveExcel: PropTypes.func,
  OnDateRangeChange: PropTypes.func,
  dateRange: PropTypes.shape({ from: {}, to: {} }),
};
const defaultProps = {
  children: null,
  style: {},
  dateRange: { from: {}, to: {} },
  OnDateRangeChange: () => {},
  saveExcel: () => {},
};
const INITAL_STATES = {
  reportId: 1,
  dateRange: {
    start: moment().subtract(11, "month").toDate(),
    end: moment().subtract(1, "month").toDate(),
  },
};
class ReportLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITAL_STATES,
      reportId: props.reportId,
      dateRange: props.dateRange,
    };
    this.props.OnDateRangeChange(this.state.dateRange, this.state.reportId);
  }
  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.dateRange, this.props.dateRange)) {
      this.props.OnDateRangeChange(this.props.dateRange, this.state.reportId);
    }
  }
  handleDateChange = (event) => {
    this.setState({
      ...this.state,
      dateRange: event.target.value,
    });
  };

  render() {
    const { dateRange, reportId } = this.state;
    const { children } = this.props;
    return (
      <React.Fragment>
        <div className="d-flex flex-column w-100 position-relative">
          <button
            title="Ladda ned detaljerad Excelrapport"
            style={{ marginLeft: "10px" }}
            className="tv-report-download__floating"
            onClick={() => this.props.saveExcel(0, true)}
          >
            <span
              className="k-icon k-i-file-excel"
              style={{ marginRight: "5px" }}
            />
            <Translate content="report.exportReport" />
          </button>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              gap: "12px",
            }}
          >
            {children}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
ReportLayout.propTypes = propTypes;
ReportLayout.defaultProps = defaultProps;

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = (dispatch) => ({
  OnDateRangeChange: (dateRange, reportId) => {
    dispatch(ChangeReportDatePeriod(dateRange, reportId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportLayout);
