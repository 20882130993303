/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import { connect } from "react-redux";
import { WidgetPieCard, WidgetBasicTable } from "..";

function DashboardWidgetContainer(props) {
  const {
    type,
    data,
    title,
    description,
    gridArea,
    field,
    orientation,
    categoryField,
    tableMainField,
    dataReadyFlag,
    style,
  } = props;
  switch (type) {
    case 1:
      return (
        <WidgetBasicTable
          key={title}
          gridArea={gridArea}
          data={data}
          tableMainField={tableMainField}
          title={title}
          description={description}
          field={field}
          orientation={orientation}
          categoryField={categoryField}
          isLoading={!dataReadyFlag}
          style={style}
        />
      );
    default:
      return (
        <WidgetPieCard
          key={title}
          gridArea={gridArea}
          data={data}
          title={title}
          description={description}
          field={field}
          orientation={orientation}
          isLoading={!dataReadyFlag}
          categoryField={categoryField}
          style={style}
        />
      );
  }
}

DashboardWidgetContainer.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  field: PropTypes.string,
  orientation: PropTypes.string,
  gridArea: PropTypes.string,
  appendText: PropTypes.string,
  categoryField: PropTypes.string,
  style: PropTypes.shape({}),
  dataReadyFlag: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({})),
};
DashboardWidgetContainer.defaultProps = {
  data: [],
  dataReadyFlag: false,
};
const mapStateToProps = (state, props) => {
  const data =
    state.reportDataSources[props.reportId].crossFilterData[
      props.tableSourceId
    ];
  const dataReadyFlag =
    state.reportDataSources[props.reportId]?.dataReadyFlags[
      props.tableSourceId
    ];
  return {
    data: data || [],
    dataReadyFlag: dataReadyFlag || data?.length !== 0 || false,
  };
};

export default connect(mapStateToProps, null)(DashboardWidgetContainer);
