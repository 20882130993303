import * as actionTypes from '../actions/ActionTypes';
import {
  LOCAL_STORE_APIKEY,
  LOCAL_STORE_CUSTOMER_NAME,
  LOCAL_STORE_USER_DATA,
  LOCAL_STORE_CUSTOMER_PASSWORD,
  LOCAL_STORE_CUSTOMER_NUMBER,
} from '../../Share/constants';

const initToken = localStorage.getItem(LOCAL_STORE_APIKEY);
const initCustomerName = localStorage.getItem(LOCAL_STORE_CUSTOMER_NAME);
const initPasswordHash = localStorage.getItem(LOCAL_STORE_CUSTOMER_PASSWORD);

const INITIAL_STATE = {
  apiKey: initToken,
  loggedIn: (initToken !== null),
  customerName: initCustomerName,
  passwordHash: initPasswordHash,
};

function localRemove() {
  localStorage.removeItem(LOCAL_STORE_APIKEY);
  localStorage.removeItem(LOCAL_STORE_CUSTOMER_PASSWORD);
  localStorage.removeItem(LOCAL_STORE_USER_DATA);
  localStorage.removeItem(LOCAL_STORE_CUSTOMER_NUMBER);
  localStorage.removeItem(LOCAL_STORE_CUSTOMER_NAME);
}

const authentication = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.RESET_APP:
      localRemove();
      return {
        ...INITIAL_STATE,
        apiKey: null,
        loggedIn: false,
        passwordHash: null,
      };

    case actionTypes.AUTHENTICATE_RESOURCE_SUCCESS:
    {
      const { customerName } = action;
      localStorage.setItem(LOCAL_STORE_CUSTOMER_NAME, customerName);

      return {
        ...state,
        customerName,
      };
    }

    case actionTypes.LOGIN_SUCCESS:
    {
      const {
        apiKey,
        customerName,
        password,
      } = action;

      localStorage.setItem(LOCAL_STORE_APIKEY, apiKey);
      localStorage.setItem(LOCAL_STORE_CUSTOMER_NAME, customerName);
      localStorage.setItem(LOCAL_STORE_CUSTOMER_PASSWORD, password);

      return {
        ...state,
        loggedIn: true,
        apiKey,
        customerName,
        password,
      };
    }

    case actionTypes.LOGIN_FAILURE:
    {
      localRemove();
      return {
        ...state,
        apiKey: null,
        loggedIn: false,
      };
    }

    default:
      return state;
  }
};

export default authentication;
