import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
  ChangeDashboardDatePeriod,
  ChangeReportDatePeriod,
  onGetDataFromScource,
} from "../../actions";
import { Dashboard } from "../";

function DashboardContainer({ dateRange }) {
  const dispatch = useDispatch();
  const reports = [2, 11]; // [2, 5, 10],
  const dateChange = () => {
    dispatch(ChangeDashboardDatePeriod(dateRange));
    reports.forEach((reportId) =>
      dispatch(ChangeReportDatePeriod(dateRange, reportId))
    );
  };
  useEffect(() => {
    dateChange();
  }, [dateRange]);

  return <Dashboard />;
}

const propTypes = {
  dateRange: PropTypes.shape({
    start: PropTypes.shape({}),
    end: PropTypes.shape({}),
  }).isRequired,
};

DashboardContainer.propTypes = propTypes;

export default DashboardContainer;
