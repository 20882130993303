import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup,
} from "@progress/kendo-react-excel-export";
import { aggregateBy, process } from "@progress/kendo-data-query";
import products from "./product.json";
import { Translate } from "Share/components";
const aggregates = [
  {
    field: "UnitPrice",
    aggregate: "sum",
  },
];
const group = [
  {
    field: "Discontinued",
    aggregates: aggregates,
  },
];
const data = process(products, {
  group: group,
}).data;
const total = aggregateBy(products, aggregates);

const CustomGroupHeader = (props) => `Discontinued: ${props.value}`;

const CustomGroupFooter = (props) =>
  `SUM: \$ ${props.aggregates.UnitPrice.sum.toFixed(2)}`;

const CustomFooter = (props) =>
  `Total ${props.column.title}: \$ ${total.UnitPrice.sum}`;

function ReportExcel({ handleExport, data, sheetName, columnStruct }) {
  const excelExport = (sheet) => {
    handleExport(sheet);
  };
  return (
    <>
      <ExcelExport
        data={data}
        fileName={sheetName}
        ref={(exporter) => excelExport(exporter)}
      >
        <ExcelExportColumn
          field={columnStruct[0].field}
          title={columnStruct[0].title}
          format={columnStruct[0].format ? columnStruct[0].format : null}
          locked={columnStruct[0].locked ? columnStruct[0].locked : false}
          width={columnStruct[0].width ? columnStruct[0].width : "auto"}
          sortable={columnStruct[0].sortable ? columnStruct[0].sortable : false}
          footerCell={
            columnStruct[0].footerCell ? columnStruct[0].footerCell : null
          }
        />
        {[...columnStruct]
          .slice(1)
          .filter((x) => x.showOnGrid === true)
          .map((x, i) => {
            if (!x.subColumn) {
              return (
                <ExcelExportColumn
                  field={x.field}
                  title={x.title}
                  format={x.format ? x.format : null}
                  locked={x.locked ? x.locked : false}
                  width={x.width ? x.width : "auto"}
                  headerClassName="text-float-right"
                  sortable={x.sortable ? x.sortable : false}
                  footerCell={x.footerCell ? x.footerCell : null}
                  className={x.className ? x.className : "text-float-right"}
                  key={i}
                />
              );
            }
            return (
              <ExcelExportColumnGroup
                title={x.title}
                width={x.width ? x.width : "auto"}
                key={i}
              >
                {x.subColumn
                  .filter((y) => y.showOnGrid === true)
                  .map((column, k) => (
                    <ExcelExportColumn
                      field={column.field}
                      title={Translate({
                        content: `report.${column.title.split("-")[1]}`,
                      })}
                      format={column.format ? column.format : null}
                      locked={column.locked ? column.locked : false}
                      width={column.width ? column.width : "auto"}
                      headerClassName="text-float-right"
                      sortable={column.sortable ? column.sortable : false}
                      footerCell={column.footerCell ? column.footerCell : null}
                      className={x.className ? x.className : "text-float-right"}
                      key={k}
                    />
                  ))}
              </ExcelExportColumnGroup>
            );
          })}
      </ExcelExport>
    </>
  );
}

export default ReportExcel;
