import moment from "moment";
import * as actionTypes from "../actions/ActionTypes";

const INITIAL_STATE = {
  1: {
    id: 1,
    data: [],
    crossFilterData: [],
    dataReadyFlags: [],
  },
  2: {
    id: 2,
    data: [],
    crossFilterData: [],
    dataReadyFlags: [],
  },
  3: {
    id: 3,
    data: [],
    crossFilterData: [],
    dataReadyFlags: [],
  },
  4: {
    id: 4,
    data: [],
    crossFilterData: [],
    dataReadyFlags: [],
  },
  5: {
    id: 5,
    data: [],
    crossFilterData: [],
    dataReadyFlags: [],
  },
  6: {
    id: 6,
    data: [],
    crossFilterData: [],
    dataReadyFlags: [],
  },
  7: {
    id: 7,
    data: [],
    crossFilterData: [],
    dataReadyFlags: [],
  },
  8: {
    id: 8,
    data: [],
    crossFilterData: [],
    dataReadyFlags: [],
  },
  9: {
    id: 9,
    data: [],
    crossFilterData: [],
    dataReadyFlags: [],
  },
  10: {
    id: 10,
    data: [],
    crossFilterData: [],
    dataReadyFlags: [],
  },
  11: {
    id: 11,
    data: [],
    crossFilterData: [],
    dataReadyFlags: [],
  },
};

const app = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.RESET_APP:
      return INITIAL_STATE;
    case actionTypes.SET_REPORT_DATASOURCE: {
      const { reportId, processData, dataReadyFlags = [] } = action;
      const sourcesNewState = { ...state };
      sourcesNewState[reportId] = {
        ...sourcesNewState[reportId],
        data: processData,
        crossFilterData: processData,
        dataReadyFlags,
      };
      return {
        ...state,
        ...sourcesNewState,
      };
    }

    case actionTypes.SET_CROSS_FILTER: {
      const { sourceId, filter } = action;
      const sourcesNewState = { ...state.sources };
      sourcesNewState[sourceId] = {
        ...sourcesNewState[sourceId],
        crossFilterData: sourcesNewState[sourceId].data.filter(
          (x) => x[filter.key] === filter.value
        ),
      };
      return {
        ...state,
        sources: sourcesNewState,
      };
    }
    // case actionTypes.REMOVE_CROSS_FILTER: {
    //   const { sourceId } = action;
    //   const sourcesNewState = { ...state.sources };
    //   sourcesNewState[sourceId] = {
    //     ...sourcesNewState[sourceId],
    //     crossFilterData: sourcesNewState[sourceId].data,
    //   };
    //   return {
    //     ...state,
    //     sources: sourcesNewState,
    //   };
    // }

    default:
      return state;
  }
};

export default app;
