import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Translate } from "Share/components";

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  reportId: PropTypes.number,
  multiColumnHeader: PropTypes.bool,
  onGridRowClick: PropTypes.func.isRequired,
  columnStruct: PropTypes.arrayOf(PropTypes.object),
  cellRender: PropTypes.func,
  handleExport: PropTypes.func,
  excelFileName: PropTypes.string,
  groupings: PropTypes.arrayOf(PropTypes.object),
};

const defaultProps = {
  data: [],
  multiColumnHeader: false,
  upperColumnHeaders: [],
  reportId: 1,
  excelFileName: "Report.xlsx",
  columnStruct: [],
  handleExport: () => {},
  cellRender: (tdElement) => tdElement,
  groupings: [],
};

class ReportGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: [{ field: props.columnStruct[0].field, dir: "asc" }],
      skip: 0,
      take: 10,
    };
  }
  exportRef;
  export = (exportf) => {
    this.props.handleExport(exportf, 0);
    // this.exportRef.save();
  };

  pageChange = (event) => {
    this.setState({
      skip: event.page.skip,
      take: event.page.take,
    });
  };

  defaultCellReander = (tdElement) => ({
    ...tdElement,
    props: {
      ...tdElement.props,
      children: tdElement.props.children ? tdElement.props.children : 0,
    },
  });

  render() {
    const { data, columnStruct, excelFileName, onGridRowClick } = this.props;
    return (
      <React.Fragment>
        <Grid
          style={{ maxHeight: "500px", width: "auto", borderRadius: "15px" }}
          cellRender={this.defaultCellReander}
          sortable
          onRowClick={(e) => onGridRowClick(e)}
          data={[...orderBy(data, this.state.sort)].slice(
            this.state.skip,
            this.state.take + this.state.skip
          )}
          skip={this.state.skip}
          take={this.state.take}
          total={data.length}
          pageable={data.length > 10}
          onPageChange={this.pageChange}
          sort={this.state.sort}
          onSortChange={(e) => {
            this.setState({
              sort: e.sort,
            });
          }}
        >
          <Column
            field={columnStruct[0].field}
            title={columnStruct[0].title}
            format={columnStruct[0].format ? columnStruct[0].format : null}
            locked={columnStruct[0].locked ? columnStruct[0].locked : false}
            width={columnStruct[0].width ? columnStruct[0].width : "auto"}
            sortable={
              columnStruct[0].sortable ? columnStruct[0].sortable : false
            }
            footerCell={
              columnStruct[0].footerCell ? columnStruct[0].footerCell : null
            }
          />
          {[...columnStruct]
            .slice(1)
            .filter((x) => x.showOnGrid === true)
            .map((x, i) => {
              if (!x.subColumn) {
                return (
                  <Column
                    field={x.field}
                    title={x.title}
                    format={x.format ? x.format : null}
                    locked={x.locked ? x.locked : false}
                    width={x.width ? x.width : "auto"}
                    headerClassName="text-float-right"
                    sortable={x.sortable ? x.sortable : false}
                    footerCell={x.footerCell ? x.footerCell : null}
                    className={x.className ? x.className : "text-float-right"}
                    key={i}
                  />
                );
              }
              return (
                <Column
                  field={x.subColumn[0].field}
                  title={Translate({
                    content: `report.${x.subColumn[0].title.split("-")[1]}`,
                  })}
                  format={x.subColumn[0].format ? x.subColumn[0].format : null}
                  locked={x.subColumn[0].locked ? x.subColumn[0].locked : false}
                  width={x.subColumn[0].width ? x.subColumn[0].width : "auto"}
                  headerClassName="text-float-right"
                  sortable={
                    x.subColumn[0].sortable ? x.subColumn[0].sortable : false
                  }
                  footerCell={
                    x.subColumn[0].footerCell ? x.subColumn[0].footerCell : null
                  }
                  className={x.className ? x.className : "text-float-right"}
                  key={i}
                />
              );
            })}
        </Grid>
      </React.Fragment>
    );
  }
}
ReportGrid.propTypes = propTypes;
ReportGrid.defaultProps = defaultProps;

export default ReportGrid;
