import React from "react";
import PropTypes from "prop-types";
import { LoadingOverlay } from "../../Share/components";
import { Logo } from "../components";
import "./style.scss"

const propTypes = {
  children: PropTypes.element.isRequired,
};

const defaultProps = {};

const LoginLayout = ({ children }) => (
  <>
    <div className="tv-login-layout__container">
      <Logo />
      <div className="tv-login-layout__form-container">{children} </div>
    </div>
    <LoadingOverlay />
  </>
);

LoginLayout.propTypes = propTypes;
LoginLayout.defaultProps = defaultProps;

export default LoginLayout;
