import moment from "moment";
import React, { useState } from "react";
import { useRouteMatch } from "react-router";
import {
  HomeSideNav,
  DashboardContainer,
  ReportContainer,
  MonthRangePicker,
} from "../..";
import "./style.scss";

function Home() {
  const { path = "", params } = useRouteMatch();
  const [dateRange, setDateRange] = useState({
    start: moment().startOf("month").subtract(1, "month"),
    end: moment().startOf("month"),
  });

  const handleMonthPickerChange = (pickerValue) => {
    if (pickerValue?.length < 2 || !pickerValue[0] || !pickerValue[1]) return;
    setDateRange({
      start: moment(pickerValue[0]).startOf("month"),
      end: moment(pickerValue[1]).endOf("month"),
    });
  };

  return (
    <div className="kpi-home__container">
      <div className="kpi-home__side-bar ">
        {/* <div className="kpi-home__side-bar_closer" onClick={() => setIsSideBarCollapse(!isSideBarCollapse)} >{"<"}</div> */}
        <div className="kpi-home__date-picker">
          {" "}
          <MonthRangePicker
            searchDateRange={dateRange}
            onChange={handleMonthPickerChange}
          />
        </div>
        <HomeSideNav />
      </div>
      <div className="kpi-home__content-area">
        {!path.includes("report") ? (
          <DashboardContainer dateRange={dateRange} />
        ) : (
          <ReportContainer reportID={params?.id} dateRange={dateRange} />
        )}
      </div>
    </div>
  );
}

export default Home;
