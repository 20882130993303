/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardActions, Menu, MenuItem } from '@progress/kendo-react-layout';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Popup } from '@progress/kendo-react-popup';
import { Translate } from '../../../Share/components';

const propTypes = {
  setColumnStruct: PropTypes.func,
  title: PropTypes.string,
  columnStruct: PropTypes.arrayOf(PropTypes.shape({})),
  handleExport: PropTypes.func,
  handleToogle: PropTypes.func,
};
const defaultProps = {
  columnStruct: [],
  title: '',
  setColumnStruct: () => { },
  handleExport: () => { },
  handleToogle: () => { },
};

export class ReportCardToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = { visibleConfig: false };
  }
  anchor = null;
  toogleVisibleConfig = () => {
    this.setState({
      ...this.state,
      visibleConfig: !this.state.visibleConfig,
    });
  }
  render() {
    const {
      handleToogle, handleExport, columnStruct, setColumnStruct, title,
    } = this.props;
    const { visibleConfig } = this.state;
    return (
      <React.Fragment>
        <div className="exact-col-2">
          <div style={{ float: 'left' }}> <span className="report-card-header">{title}</span></div>

          <div style={{ float: 'right' }}>
            <span
              role="button"
              className="k-icon k-i-grid-layout p-1"
              style={{
                float: 'right', width: '40px', margin: '5px', cursor: 'pointer',
              }}
              onKeyPress={handleToogle}
              onClick={handleToogle}
            />
            <span
              role="button"
              className=" auto-col-2 k-icon k-i-download p-1"
              style={{
                float: 'right', width: '40px', margin: '5px', cursor: 'pointer',
              }}

              onKeyPress={handleExport}
              onClick={handleExport}
            />
            <div>
              <button
                className="k-button k-bare auto-col-2"
                ref={(button) => {
                  this.anchor = button;
                }}
                style={{ float: 'right', gridGap: '10px', cursor: 'pointer' }}
                onKeyPress={this.toogleVisibleConfig}
                onClick={this.toogleVisibleConfig}
              >{Translate({ content: 'report.columns' })} <span className="k-icon k-i-gear" style={{ paddingBottom: '20px' }} />
              </button>
              <Popup
                anchor={this.anchor}
                show={visibleConfig}
              >
                <ColumnSettingModal setColumnStruct={(colStruct) => { setColumnStruct(colStruct); this.toogleVisibleConfig(); }} onCancelClick={() => this.toogleVisibleConfig()} columnStruct={columnStruct} />
              </Popup>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ReportCardToolbar.propTypes = propTypes;
ReportCardToolbar.defaultProps = defaultProps;

export default ReportCardToolbar;

const ColumnSettingModal = (props) => {
  const [columnStruct, setChangeColumnStruct] = useState([...props.columnStruct]);

  const handleChange = (event, index) => {
    const column = columnStruct[index];
    const newColumnStruct = [...columnStruct];
    newColumnStruct.splice(index, 1, { ...column, showOnGrid: event.value });
    setChangeColumnStruct(newColumnStruct);
  };
  return (
    <Card style={{ width: 300 }}>
      <CardBody>
        {columnStruct.map((x, i) => (i !== 0 ? (
          <div style={{ width: '100%', display: 'block' }} key={i}>
            <Checkbox value={x.showOnGrid} onChange={e => handleChange(e, i)} label={x.title} />
          </div>
        ) : null))}
      </CardBody>
      <CardActions layout="stretched">
        <span className="k-button k-flat k-primary" onClick={() => props.setColumnStruct(columnStruct)}>{Translate({ content: 'general.confirm' })}</span>
        <span className="k-button k-flat k-primary" onClick={() => props.onCancelClick()}>{Translate({ content: 'general.cancel' })}</span>
      </CardActions>
    </Card>
  );
};
