import React from "react";

import { ThreeDotLoader, Translate } from "Share/components";
import style from "./style.module.scss";
import { numberFormat } from "Share/utils/reportUtil";

import { ReportChart, ReportGrid } from "ReportPortal/components";

const totalFooterCell = (props, tableData) => {
  if (props.field !== "orderUnitName") {
    const total = tableData.reduce(
      (acc, current) =>
        acc +
        (current[props.field] ? Number(current[props.field].toFixed(0)) : 0),
      0
    );
    return (
      <td
        colSpan={props.colSpan}
        style={{
          ...props.style,
          color: "#000",
          textAlign: "right",
          paddingRight: "5%",
        }}
      >
        {numberFormat(total) || 0}
      </td>
    );
  }
  return (
    <td
      colSpan={props.colSpan}
      style={{
        ...props.style,
        color: "#000",
        textAlign: "right",
        paddingRight: "5%",
      }}
    >
      {""}
    </td>
  );
};

function ReportContent({
  isDataLoaded,
  data,
  activeView,
  columnStruct,
  primaryColumn,
  aggregateColumn,
  table,
  excelFileName,
}) {
  if (isDataLoaded) {
    return (
      <div className={style.loaderContent}>
        <ThreeDotLoader />
      </div>
    );
  }
  if (activeView === "chart") {
    return (
      <div className={style.content}>
        <ReportChart
          table={table}
          data={data}
          primaryColumn={primaryColumn}
          aggregateColumn={aggregateColumn}
        />
      </div>
    );
  }
  return (
    <div className={style.content}>
      <ReportGrid
        excelFileName={excelFileName}
        columnStruct={[
          columnStruct[0],
          ...columnStruct.slice(1).map((column) =>
            table.type !== "distribute property group"
              ? {
                  ...column,
                  footerCell: (props) => totalFooterCell(props, data, table.id),
                }
              : {
                  ...column,
                  subColumn: column.subColumn.map((x) => ({
                    ...x,
                    footerCell: (props) =>
                      totalFooterCell(props, data, table.id),
                  })),
                }
          ),
        ]}
        data={data}
      />
    </div>
  );
}

export default ReportContent;
