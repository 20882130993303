import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

const Avatar = ({ className }) => (
  <div className="tv-avatar">
    <img className={className} src="/images/Avatar.png" alt="logo" />
    {/* <img src="/images/Avatar.jpg" class="tv-avatar__img" alt="avatar"> */}
  </div>
);
Avatar.propTypes = {
  className: PropTypes.string,
};

Avatar.defaultProps = {
  className: "tv-avatar__img",
};

export default Avatar;
