import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  buttonType: PropTypes.string,
  disabled: PropTypes.bool,
};

const defaultProps = {
  className: '',
  onClick: () => { },
  type: '',
  text: PropTypes.string,
  icon: '',
  buttonType: 'button',
  disabled: false
};

const TransvoiceButton = ({icon, className, onClick, type, text, buttonType, disabled }) => {
  const typeClass = classNames({
    'tv-buttons__element': true,
    'tv-buttons__element--line-default': !type,
    [`tv-buttons__element--${type} `]: type,
    [className]: !!className,
    'tv-buttons__element--disable': disabled,
  });

  if (buttonType === 'file') {
    return (
      <input type="file" onClick={onClick} className={typeClass} title={text} disabled={disabled} />
    );
  }

  const iconComponent = icon && <i className={icon} />
  return (<button type={buttonType} onClick={onClick} className={typeClass} disabled={disabled}>{iconComponent}{text}</button>);
};

TransvoiceButton.propTypes = propTypes;
TransvoiceButton.defaultProps = defaultProps;

export default TransvoiceButton;
