import React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TabButton } from "ReportPortal/components";
import {
  numberFormat,
  summationBasedOnKey,
} from "../../../../Share/utils/reportUtil";
import style from "./style.module.scss";

function ReportControllers({
  type,
  onAggregatedFieldSelect,
  onPrimaryColumnSelect,
  description,
  primaryColumns,
  aggregatedFields,
  onReportViewSet,
  activeView,
  onExcelDownload,
  controller,
  tableTotal,
  data,
}) {
  const toggleView = (event, tab) => {
    event.preventDefault();
    onReportViewSet(tab);
  };
  return (
    <div
      className={
        type === "distribute property group"
          ? style.controllersModified
          : style.controllers
      }
    >
      {!(type === "distribute property group") &&
        controller?.aggregateColVisible && (
          <>
            {" "}
            <div className="tv-report-dropdown">
              <label>Quantify</label>
              <DropDownList
                data={aggregatedFields}
                textField="title"
                className="tv-single-assign-form__control"
                dataItemKey="field"
                defaultValue={aggregatedFields[0]}
                onChange={onAggregatedFieldSelect}
              />
            </div>
          </>
        )}
      {type === "distribute property group" && (
        <>
          <div className="tv-report-dropdown">
            <label>Visa för:​ </label>
            <DropDownList
              data={primaryColumns}
              textField="title"
              className="tv-single-assign-form__control"
              dataItemKey="title"
              defaultValue={primaryColumns[0]}
              onChange={onPrimaryColumnSelect}
            />
          </div>
          {controller?.aggregateColVisible && (
            <div className="tv-report-dropdown">
              <label>Quantify </label>
              <DropDownList
                data={aggregatedFields}
                textField="title"
                className="tv-single-assign-form__control"
                dataItemKey="title"
                defaultValue={aggregatedFields[0]}
                onChange={onAggregatedFieldSelect}
              />
            </div>
          )}
        </>
      )}
      <div className={style.tabContainer}>
        <TabButton
          label="Graf"
          title="View Top result in chart"
          disabled={activeView == "chart"}
          onClick={(e) => toggleView(e, "chart")}
        />
        <TabButton
          label="Tabell"
          title="View Top result in table"
          disabled={activeView == "table"}
          onClick={(e) => toggleView(e, "table")}
        />
      </div>
      <div className={style.reportDescription}>{description}</div>
      {tableTotal && (
        <div className={style.tableTotalContainer}>
          <div className={style.tableTotalLabel}>{tableTotal.label}</div>
          <div className={style.tableTotal}>
            {`${numberFormat(
              summationBasedOnKey([...data], tableTotal.field),
              0
            )} ${tableTotal.suffix}`}
          </div>
        </div>
      )}
      <div
        className={style.downloadArea}
        onClick={onExcelDownload}
        title="Export Report"
      >
        <i class="icon-download-file"></i>
      </div>
    </div>
  );
}

export default ReportControllers;
