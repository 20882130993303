import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LOCAL_STORE_TOKEN, LOCAL_STORE_APIKEY } from '../../Share/constants';

const propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  layout: PropTypes.elementType.isRequired,
};

const defaultProps = {
};

const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      localStorage.getItem(LOCAL_STORE_APIKEY) ?
        <Layout>
          <Component {...props} />
        </Layout>
        :
        <Redirect to={{
          pathname: '/login',
          state: { from: props.location },
        }}
        />
    )}
  />
);

PrivateRoute.propTypes = propTypes;
PrivateRoute.defaultProps = defaultProps;

export default PrivateRoute;
