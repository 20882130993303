/* eslint-disable max-len */
import axios from 'axios';
import { errorInterceptor, responseInterceptor } from './apiInterceptor';


const apiVersion = process.env.REACT_APP_REPORT_API_VERSION;
const reportUserAxios = axios.create({
  baseURL: process.env.REACT_APP_REPORT_API,
});
// api interceptors
reportUserAxios.interceptors.response.use(responseInterceptor, errorInterceptor);

// eslint-disable-next-line import/prefer-default-export
export function getUserData(userId, apiKey) {
  return reportUserAxios.get(`/api/${apiVersion}/user/${userId}/data`, {
    headers: {
      customerNumber: 0,
      apiKey,
    },
  });
}

export function validateCustomerPassword(userName, password) {
  return reportUserAxios.post(`/api/${apiVersion}/Account/sign-in`, {
    userName,
    password,
  });
}
