import React from "react";
import {
  Chart,
  ChartArea,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartCategoryAxisTitle,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import { Circle, geometry } from "@progress/kendo-drawing";

const { Circle: CircleGeometry } = geometry;

function ReportChart({ table, data, primaryColumn, aggregateColumn }) {
  const { categoryField, field, title, chartType } = table.chart;
  return table.type === "distribute property group"
    ? simpleChartRender(
        table.columnStruct[0].field,
        data,
        {
          field: `${primaryColumn?.value || ""}-${aggregateColumn.field}`,
          format: aggregateColumn?.format || "",
        },
        table.columnStruct[0].title,
        chartType,
        table.showAsPercentage
      )
    : simpleChartRender(
        categoryField,
        data,
        aggregateColumn,
        table.columnStruct[0].title,
        chartType,
        table.showAsPercentage
      );
}

function simpleChartRender(
  categoryField,
  data,
  aggregateColumn,
  axisTitle,
  chartType,
  showAsPercentage
) {
  const total = data.reduce((acc, cur) => acc + cur[aggregateColumn.field], 0);
  if (showAsPercentage) {
    const transformData = data.map((item) => {
      const returnObj = {
        ...item,
      };
      returnObj[aggregateColumn.field] =
        returnObj[aggregateColumn.field] / total;
      return returnObj;
    });
    return (
      <React.Fragment>
        <div style={{ display: "flex", width: "100%" }}>
          <Chart
            renderAs="canvas"
            style={{
              width: "100%",
              fontFamily: "'Circular Std'",
              backgroundColor: "#EBEBEB",
            }}
          >
            <ChartLegend
              position="right"
              item={{
                visual: (e) => {
                  const defaultVisual = e.createVisual();
                  const group = defaultVisual.children;
                  const fillColor = group[0].options.fill.color;

                  const circleGeom = new CircleGeometry([10, 7], 4);
                  const circle = new Circle(circleGeom, {
                    fill: {
                      color: fillColor,
                    },
                    stroke: {
                      color: fillColor,
                    },
                  });

                  group[0] = circle;

                  return defaultVisual;
                },
              }}
            />
            <ChartArea background="#fff" margin={20} />
            <ChartSeries>
              <ChartSeriesItem
                type="pie"
                tooltip={{ visible: true, format: "p" }}
                data={transformData}
                categoryField={categoryField}
                field={aggregateColumn.field}
              ></ChartSeriesItem>
            </ChartSeries>
            <ChartCategoryAxis>
              <ChartCategoryAxisItem labels={{ padding: 10, rotation: "auto" }}>
                <ChartCategoryAxisTitle text={axisTitle} />
              </ChartCategoryAxisItem>
            </ChartCategoryAxis>
            <ChartValueAxis>
              <ChartValueAxisItem />
            </ChartValueAxis>
          </Chart>
        </div>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <div style={{ display: "flex", width: "100%" }}>
        <Chart
          renderAs="canvas"
          style={{
            width: "100%",
            fontFamily: "'Circular Std'",
            backgroundColor: "#EBEBEB",
          }}
        >
          <ChartArea background="#fff" margin={20} />
          <ChartSeries>
            <ChartSeriesItem
              type="bar"
              tooltip={{ visible: true, format: aggregateColumn.format }}
              data={data}
              categoryField={categoryField}
              field={aggregateColumn.field}
            ></ChartSeriesItem>
          </ChartSeries>
          <ChartCategoryAxis>
            <ChartCategoryAxisItem labels={{ padding: 10, rotation: "auto" }}>
              <ChartCategoryAxisTitle text={axisTitle} />
            </ChartCategoryAxisItem>
          </ChartCategoryAxis>
          <ChartValueAxis>
            <ChartValueAxisItem
              labels={{
                format: aggregateColumn.format,
                rotation: "auto",
              }}
            />
          </ChartValueAxis>
        </Chart>
      </div>
    </React.Fragment>
  );
}

export default ReportChart;
